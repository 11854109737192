import { createSlice } from "@reduxjs/toolkit";

const bookingProcessState = {
  storeName: "",
  storeNameLoading: true,
  cusNumbers: [],
  activeBrands: [],
  targetBrands: [],
  activeBrandsLoading: false,
  targetBrandsLoading: false,
  selectedBrands: [],
  brandAvailabilities: [],
  timeSlots: [],
  allTimezones: {},
  timezoneOffset: null,
  bookings: [],
  bookingError: "",
  categories: [],
  notes: "",
  skippedBrands: [],
  salesCampaignFlag: false,
  salesCampaignFlagLoading: true,
  hubspotError: "",
  bookingLinks: {},
  contactInfo: {},
};

const reducers = {
  setStoreName(state, action) {
    state.storeName = action.payload;
  },
  setStoreNameLoading(state, action) {
    state.storeNameLoading = action.payload;
  },
  activeBrandsReceived(state, action) {
    state.activeBrands = action.payload;
  },
  targetBrandsReceived(state, action) {
    state.targetBrands = action.payload;
  },
  activeBrandsLoading(state, action) {
    state.activeBrandsLoading = action.payload;
  },
  targetBrandsLoading(state, action) {
    state.targetBrandsLoading = action.payload;
  },
  setBrandAvailabilities(state, action) {
    state.brandAvailabilities = action.payload;
  },
  setSelectedBrands(state, action) {
    state.selectedBrands = action.payload;
  },
  setCusNumbers(state, action) {
    state.cusNumbers = action.payload;
  },
  setTimeSlots(state, action) {
    state.timeSlots = action.payload;
  },
  setTimezones(state, action) {
    state.allTimezones = action.payload;
  },
  setTimezoneOffset(state, action) {
    state.timezoneOffset = action.payload;
  },
  setBookings(state, action) {
    state.bookings = action.payload;
  },
  setBookingError(state, action) {
    state.bookingError = action.payload;
  },
  setCategories(state, action) {
    state.categories = action.payload;
  },
  setNotes(state, action) {
    state.notes = action.payload;
  },
  setSkippedBrands(state, action) {
    state.skippedBrands = action.payload;
  },
  setSalesCampaignFlag(state, action) {
    state.salesCampaignFlag = action.payload;
  },
  setSalesCampaignFlagLoading(state, action) {
    state.salesCampaignFlagLoading = action.payload;
  },
  setHubspotError(state, action) {
    state.hubspotError = action.payload;
  },
  setBookingLinks(state, action) {
    state.bookingLinks = action.payload;
  },
  setContactInfo(state, action) {
    state.contactInfo = action.payload;
  },
};

export const slice = createSlice({
  name: "bookingProcess",
  initialState: bookingProcessState,
  reducers,
});

export const { reducer } = slice;
