import axios from "axios";

const deployEnvironment = require("../../deployEnvironment.js");
const API = deployEnvironment;

class BookingProcessAPI {
  async verifyCustomerId(userId) {
    if (!userId) return false;

    const response = await axios.get(`${API}/api/verifyCustomerId/${userId}`);

    const storeName = response.data.storeName;

    return new Promise((resolve, reject) => {
      resolve(storeName);
    });
  }
  async getCustomerActiveBrands(userId, salesCampaignId) {
    if (!userId || !salesCampaignId) return false;

    const response = await axios.get(
      `${API}/api/getCustomerBrands/${salesCampaignId}/${userId}`
    );

    const activeBrands = response.data.activeBrands;

    return new Promise((resolve, reject) => {
      try {
        if (!activeBrands) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(activeBrands);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getCustomerTargetBrands(userId, salesCampaignId) {
    if (!userId || !salesCampaignId) return false;

    const response = await axios.get(
      `${API}/api/getCustomerBrands/${salesCampaignId}/${userId}`
    );

    const targetBrands = response.data.targetBrands;

    return new Promise((resolve, reject) => {
      try {
        if (!targetBrands) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(targetBrands);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getBrandAvailabilities(selectedBrands, salesCampaignId) {
    const response = await axios.post(`${API}/api/getBrandAvailabilities`, {
      selectedBrands,
      salesCampaignId,
    });

    const brandAvailabilities = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!brandAvailabilities) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(brandAvailabilities);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getCusNumbers(userId, salesCampaignId) {
    if (!userId || !salesCampaignId) return false;

    const response = await axios.get(
      `${API}/api/getCustomerBrands/${salesCampaignId}/${userId}`
    );

    const cusNumbers = response.data.cusNumbers;

    return new Promise((resolve, reject) => {
      try {
        if (!cusNumbers) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(cusNumbers);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getDeals(userId, properties) {
    if (!userId) return false;

    const response = await axios.post(
      `${API}/api/getDeals/${userId}`,
      properties
    );

    const cusNumbers = response.data.deals;

    return new Promise((resolve, reject) => {
      try {
        if (!cusNumbers) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(cusNumbers);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getTimeSlots(userId, properties) {
    if (!userId) return false;

    const response = await axios.post(
      `${API}/api/getTimeSlots/${userId}`,
      properties
    );

    const data = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!data) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(data);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getTimezoneOffset(userId, location, date) {
    if (!userId) return false;

    const response = await axios.post(
      `${API}/api/getTimezoneOffset/${userId}`,
      { location, date }
    );

    const data = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!data) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(data);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getAllTimezones(userId, date) {
    if (!userId) return false;

    const response = await axios.post(`${API}/api/getAllTimezones/${userId}`, {
      date,
    });

    const data = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!data) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(data);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async createBookings(
    salesCampaignId,
    userId,
    bookings,
    cusNumbers,
    categories,
    notes
  ) {
    if (!userId) return false;

    try {
      const createBookingsResponse = await axios.post(
        `${API}/api/create-booking/${salesCampaignId}/${userId}`,
        { bookings, cusNumbers, categories, notes }
      );

      return createBookingsResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }
  async getCategories(userId, salesCampaignId) {
    if (!userId || !salesCampaignId) return false;

    const response = await axios.get(
      `${API}/api/getCustomerBrands/${salesCampaignId}/${userId}`
    );

    const categories = response.data.categories;

    return new Promise((resolve, reject) => {
      try {
        if (!categories) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(categories);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async verifySalesCampaign(userId, salesCampaignId) {
    if (!salesCampaignId) return false;

    const response = await axios.get(
      `${API}/api/verifySalesCampaign/${salesCampaignId}/${userId}`
    );

    const salesCampaignExists = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!salesCampaignExists) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(salesCampaignExists);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async updateDealsOfSkippedBrands(userId, salesCampaignId, skippedBrands) {
    try {
      await axios.post(
        `${API}/api/updateDealsOfSkippedBrands/${salesCampaignId}/${userId}`,
        {
          client: userId,
          brands: skippedBrands,
        }
      );
    } catch (err) {
      throw err.response.data;
    }
  }
  async getBookingLinks(userId) {
    if (!userId) return false;

    const response = await axios.get(`${API}/api/getBookingLinks/${userId}`);

    const bookingLinks = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!bookingLinks) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(bookingLinks);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getContactInfo(userId) {
    if (!userId) return false;

    const response = await axios.get(`${API}/api/getContactInfo/${userId}`);

    const contactInfo = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!contactInfo) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(contactInfo);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const bookingProcessApi = new BookingProcessAPI();
